import React, { Component } from "react";
import { Auth } from "aws-amplify";

import { Row, Col, Container, Form, Button } from "react-bootstrap";
// import MembershipDetails from "./membershipDetails";
import { Link } from "react-router-dom";
import config from "../config";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
    paddingRight: 20,
    paddingLeft: 20,
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class ActivateBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      dropdownArray: [],
      packageDropdown: [],
      showData: true,
      userName: "",
      userPhoneNumber: "",
      membershipArray: [],
      userPincode: "",
      shopName: "",
      shopAddress: "",
      user: "",
      lastUpdatedby: "",
      currentDate: new Date().toISOString().substr(0, 10),
      //   lastMembershipexpiry: "",
      userInfo: {},
      lastUpdatedOn: "",
      billing: {
        userId: "",
        billingTransactionid: "",
        billingPackage: "",
        billingUpdatedBy: "",
        billingExpiryDate: "",
        activationType: "paid",
      },
      pastBillHistory: {},
      lastUpdatedby: "",
      lastUpdatedon: "",
      PreviousloyaltyRatio: "",
      billingRecords: [],
      ActivatedRadio: [],
    };
    this.billingPackageSubmit = this.billingPackageSubmit.bind(this);

    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.reset = this.state;

    this.billingOnchange = this.billingOnchange.bind(this);
  }
  //   componentDidUpdate() {
  //     window.onpopstate = e => {
  //       e.preventDefault();
  //       if (window.confirm("are you sure want to leave the page ?")) {
  //         window.location.assign("/");
  //       } else {
  //         window.location.assign("/loyalty");
  //       }
  //     };
  //   }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  billingOnchange(e) {
    this.setState({
      billing: {
        ...this.state.billing,
        [e.target.name]: e.target.value,
      },
    });
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) => {
        let user = { email: data.email, ...data.attributes };

        this.setState({
          ...this.state,
          billing: {
            ...this.state.billing,
            billingUpdatedBy: user.phone_number,
          },
        });
      })

      .catch((err) => console.log(err));
  }

  getShop(e) {
    e.preventDefault();
    fetch(
      `${config.ApiGateway}/getshop`,

      {
        method: "POST",

        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let userInfo = {};
        if (data.Items.length > 0) {
          data.Items.forEach((headers) => {
            if (headers.userType === "Shop") {
              userInfo = headers;
            }
          });
          let pastBills = [];
          Object.entries(userInfo["billingHistory"] || {}).forEach(
            ([key, value]) => {
              pastBills.push(value);
            }
          );
          pastBills = pastBills.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );

          this.setState({
            showData: false,
            userInfo: userInfo,
            lastUpdatedby: userInfo["billingUpdatedBy"],
            lastUpdatedon: userInfo["billingupdatedOn"],
            pastBillHistory: userInfo["billingHistory"] || {},
            billingRecords: pastBills,
            billing: {
              ...this.state.billing,
              userId: userInfo["userId"],
              billingTransactionid: userInfo["billingTransactionid"],
              billingPackage: userInfo["billingPackage"],

              billingExpiryDate: userInfo["billingExpiryDate"],
            },
          });
        } else {
          this.setState({
            message: "NO SHOPS FOUND",
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: "NO SHOPS FOUND",
        });
      });
  }
  billingPackageSubmit(e) {
    e.preventDefault();
    fetch(`${config.ApiGateway}/billingPackageUpdate`, {
      method: "POST",
      body: JSON.stringify(this.state.billing),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert(
          `${data.status} ${
            this.state.billing.activationType == "paid"
              ? "  & Bill Generated"
              : ""
          }`
        );
        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { classes } = this.props;
    return (
      <Container fluid>
        <Row className="rowClass">
          <b style={{ fontSize: 32 }}>Activate Billing</b>
        </Row>
        {this.state.showData ? (
          <>
            <Form onSubmit={this.getShop}>
              <Row style={{ marginTop: 10 + "%" }}>
                <Col xs={{ span: 4, offset: 4 }}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Enter Shop ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="userId10Digit"
                      value={this.state.userId10Digit}
                      onChange={this.onChange}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    style={{
                      width: 100 + "%",
                      height: 30 + "%",
                      marginTop: 2 + "%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            <Row className="rowClass">
              <Col className="division" xs={5}>
                <>
                  <div className="rowClass">
                    <h5>
                      {" "}
                      <b style={{ fontSize: 18 }}>Shop Details</b>
                    </h5>
                  </div>

                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Shop Name</Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["shopName"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>User ID</Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userId10Digit"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Name</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userName"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Pincode</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userPincode"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Phone Number</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userPhoneNumber"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Shop Type</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["shopType"]}
                      />
                    </Col>
                  </Form.Group>
                </>
              </Col>
              <Col className="division" xs={5}>
                <div className="rowClass">
                  <h5>
                    {" "}
                    <b style={{ fontSize: 18 }}>Package Details</b>
                  </h5>
                </div>
                <Form onSubmit={this.billingPackageSubmit}>
                  <>
                    <Form.Group as={Row}>
                      <Form.Label column>Activate Billing Package</Form.Label>

                      <Col xs={3}>
                        <Form.Check
                          type="radio"
                          name="billingPackage"
                          onChange={this.billingOnchange}
                          label={"YES"}
                          value="yes"
                          checked={this.state.billing.billingPackage === "yes"}
                        />
                      </Col>
                      <Col xs={3}>
                        <Form.Check
                          type="radio"
                          name="billingPackage"
                          onChange={this.billingOnchange}
                          label={"NO"}
                          value="no"
                          checked={this.state.billing.billingPackage === "no"}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column>Transaction ID</Form.Label>
                      <Col>
                        <Form.Control
                          name="billingTransactionid"
                          onChange={this.billingOnchange}
                          value={this.state.billing.billingTransactionid}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column>Billing Expiry Date</Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="billingExpiryDate"
                          onChange={this.billingOnchange}
                          value={this.state.billing.billingExpiryDate}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column>Activation Type</Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          name="activationType"
                          onChange={this.billingOnchange}
                          value={this.state.billing.activationType}
                        >
                          <option value="free">Free</option>
                          <option value="paid">Paid</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <div className="btnalignright">
                      <Button type="submit">Submit</Button>
                    </div>
                  </>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  width: 100 + "%",

                  justifyContent: "center",
                }}
                sm={6}
              >
                <h6>Last package Updated by</h6>
                <b>{this.state.lastUpdatedby}</b>
              </Col>
              <Col
                style={{
                  width: 100 + "%",

                  justifyContent: "center",
                }}
                sm={6}
              >
                <h6>Last package Updated on</h6>
                <b>{this.state.lastUpdatedon}</b>
              </Col>
            </Row>
            <div style={{ width: "100%", height: "40px" }}></div>

            <h4>Bill History</h4>

            <div style={{ width: "100%", height: "10px" }}></div>

            <Table
              className="division"
              style={{
                width: 100 + "%",
                border: 0.5 + "px",
                borderColor: "#ced4da",
                borderStyle: "solid",
              }}
              size="small"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    Billing Date
                  </TableCell>
                  <TableCell className={classes.tableCell}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.billingRecords.map((value) => (
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      {value.date}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <>
                        <a href={value.pdfURL}>
                          <Button>View Details</Button>
                        </a>
                      </>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Container>
    );
  }
}
ActivateBilling.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivateBilling);
