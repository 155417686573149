import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Typography } from "@material-ui/core";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
// import MembershipDetails from "./membershipDetails";
import config from "../config";
export default class ActiveLoyalty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      dropdownArray: [],
      packageDropdown: [],
      showData: true,
      userName: "",
      userPhoneNumber: "",
      membershipArray: [],
      userPincode: "",
      shopName: "",
      shopAddress: "",
      user: "",
      lastUpdatedby: "",
      currentDate: new Date().toISOString().substr(0, 10),
      //   lastMembershipexpiry: "",
      lastUpdatedOn: "",
      loyalty: {
        loyaltyPackage: "",
        loyaltyTransactionid: "",
        loyaltyupdatedOn: new Date().toISOString().substr(0, 10),
        loyaltyupdatedBy: "",
        loyaltyDuration: "",
        loyaltyNotes: "",
      },
      PreviousloyaltyPoints: "",
      PreviousloyaltyAmount: "",
      PreviousloyaltyRatio: "",

      ActivatedRadio: [],
    };
    this.loyaltyUpdateSubmit = this.loyaltyUpdateSubmit.bind(this);
    this.loyaltyPackageOnchange = this.loyaltyPackageOnchange.bind(this);
    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.reset = this.state;
    this.loadDetails = this.loadDetails.bind(this);
  }
  componentDidUpdate() {
    window.onpopstate = (e) => {
      e.preventDefault();
      if (window.confirm("are you sure want to leave the page ?")) {
        window.location.assign("/");
      } else {
        window.location.assign("/loyalty");
      }
    };
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) => {
        let user = { email: data.email, ...data.attributes };

        this.setState(
          {
            ...this.state,
            loyalty: {
              ...this.state.loyalty,
              loyaltyupdatedBy: user.phone_number,
            },
          },
          () => {
            this.loadDetails();
          }
        );
      })

      .catch((err) => console.log(err));
  }

  loadDetails() {
    Promise.all([
      fetch(
        // "https://ghoshak-banner.s3.ap-south-1.amazonaws.com/jsonFiles/loyaltypackage.json"
        `${config.json.URL}/loyaltypackage.json`
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let packageDuration = data.LOYALTY["DURATION"].map((items) => {
          return <option key={items}>{items}</option>;
        });

        return this.setState({
          packageDropdown: packageDuration,
          ActivatedRadio: data.ACTIVATED,
        });
      })
      .catch((err) => alert("function call failed"));
  }

  getShop(e) {
    e.preventDefault();
    fetch(
      // "https://c5909lgffc.execute-api.ap-south-1.amazonaws.com/dev/getshop",
      `${config.ApiGateway}/getshop`,

      {
        method: "POST",

        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        data.Items.forEach((elements) => {
          this.setState({
            ...this.state,

            loyalty: {
              ...this.state.loyalty,

              userId: elements.userId,
              loyaltyPackage: elements.loyaltyPackage,
              loyaltyTransactionid: elements.loyaltyTransactionid,
              loyaltyDuration: elements.loyaltyDuration,
              loyaltyNotes: elements.loyaltyNotes,
            },

            lastUpdatedby: elements.loyaltyupdatedBy,
            lastUpdatedOn: elements.loyaltyupdatedOn,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            shopId10Digit: elements.userId10Digit,
            userPincode: elements.userPincode,
            shopName: elements.shopName,
            shopAddress: elements.shopAddress,
            showData: false,
          });
        });
      })
      .catch((err) => {
        this.setState({
          message: "NO SHOPS FOUND",
        });
      });
  }
  loyaltyUpdateSubmit(e) {
    e.preventDefault();
    fetch(`${config.ApiGateway}/loyaltypackageupdate`, {
      method: "POST",
      body: JSON.stringify(this.state.loyalty),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert("loyalty package updated");
        this.setState(this.reset);
      })
      .catch((err) => console.log(err));
  }

  loyaltyPackageOnchange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      loyalty: {
        ...this.state.loyalty,
        [name]: value,
      },
    });
  }

  render() {
    return (
      <Container>
        {this.state.showData ? (
          <>
            <div
              style={{
                // backgroundColor: "#fafafa",
                width: 100 + "%",
                margin: 1 + "%",
              }}
            >
              <h3 style={{ textAlign: "center" }}>LOYALTY PACKAGE UPDATE</h3>
            </div>

            <Form onSubmit={this.getShop}>
              <Row>
                <Col md={{ span: 8, offset: 2 }}>
                  <Form.Label className="asterik">
                    ENTER YOUR SHOP ID
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="userId10Digit"
                    placeholder="SHOP ID"
                    onChange={this.onChange}
                    value={this.state.userId10Digit}
                    required
                  />

                  <Button
                    type="submit"
                    style={{
                      float: "right",
                      marginTop: 2 + "%",
                    }}
                  >
                    SUBMIT
                  </Button>

                  <h4 style={{ marginTop: 5 + "%" }}>{this.state.message}</h4>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            <Form onSubmit={this.loyaltyUpdateSubmit}>
              <Typography
                variant="h3"
                component="h2"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                SHOP DETAILS
              </Typography>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SHOP NAME
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="shopName"
                    onChange={this.reviewOnchange}
                    value={this.state.shopName}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  MERCHANT NAME
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userName"
                    onChange={this.reviewOnchange}
                    value={this.state.userName}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SHOP ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userId10Digit"
                    value={this.state.userId10Digit}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PHONE NUMBER
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userPhoneNumber"
                    value={this.state.userPhoneNumber}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PINCODE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="pincode"
                    onChange={this.reviewOnchange}
                    value={this.state.userPincode}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Row>
                <Col>
                  <Form.Label>ACTIVATE LOYALTY PACKAGE</Form.Label>
                </Col>

                {this.state.ActivatedRadio.map((type) => (
                  <Col>
                    <Form.Check
                      type="radio"
                      name="loyaltyPackage"
                      onChange={this.loyaltyPackageOnchange}
                      label={`${type}`.toUpperCase()}
                      value={type}
                      checked={this.state.loyalty.loyaltyPackage === `${type}`}
                    />
                  </Col>
                ))}
              </Form.Row>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  TRANSACTION ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="loyaltyTransactionid"
                    onChange={this.loyaltyPackageOnchange}
                    value={this.state.loyalty.loyaltyTransactionid}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  LOYALTY PACKAGE DURATION
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    name="loyaltyDuration"
                    onChange={this.loyaltyPackageOnchange}
                    value={this.state.loyalty.loyaltyDuration}
                  >
                    <option value="">SELECT DURATION</option>
                    {this.state.packageDropdown}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  NOTES
                </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    name="loyaltyNotes"
                    rows="6"
                    onChange={this.loyaltyPackageOnchange}
                    value={this.state.loyalty.loyaltyNotes}
                    placeholder="ENTER NOTES"
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Button
                type="submit"
                style={{
                  marginTop: 20 + "px",
                  float: "right",
                }}
              >
                submit
              </Button>
            </Form>
            <Row>
              <Col>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  LAST LOYALTY PACKAGE UPDATED/CREATED BY
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  {this.state.lastUpdatedby}
                </Typography>
              </Col>
              <Col>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  LAST LOYALTY PACKAGE UPDATED/CREATED ON
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  {this.state.lastUpdatedOn}
                </Typography>
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}
